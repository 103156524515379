import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button, ButtonGroup, Card, Col, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import axios, { AxiosError, AxiosResponse } from "axios";
import Icon from "@mdi/react";
import { mdiAsterisk, mdiCheck, mdiCheckCircle, mdiChevronLeft, mdiCircleOutline, mdiCircleSlice8, mdiBookOutline, mdiRayStartArrow, mdiRayStartEnd, mdiRayStartVertexEnd } from "@mdi/js";
import { useMutation, useQuery } from "react-query";

import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";

import {
	QuestionnaireResponseEntity,
	QuestionnaireResponsePostRequest,
	QuestionnaireResponsePostResponse,
	QuestionnaireResponsePutRequest,
	QuestionnaireResponsePutResponse,
} from "../../entities/QuestionnaireResponseEntity";
import { PerguntaTipo, QuestionnaireGetResponse, RespostaType } from "../../entities/QuestionnaireEntity";
import { QuestionGroupEntity } from "../../entities/QuestionGroupEntity";
import { QuestionEntity } from "../../entities/QuestionEntity";
import QuestionnaireResponseService from "../../services/QuestionnaireResponseService";
import QuestionnaireService from "../../services/QuestionnaireService";

import FormObjective from "../../components/FormObjective";
import FormParametro from "../../components/FormParametro";
import FormTextarea from "../../components/FormTextarea";
import FormNumber from "../../components/FormNumber";
import FormRadio from "../../components/FormRadio";
import FormCheck from "../../components/FormCheck";
import FormImage from "../../components/FormImage";
import FormText from "../../components/FormText";
import FormFile from "../../components/FormFile";
import FormDate from "../../components/FormDate";
import FormGeo from "../../components/FormGeo";
import Layout from "../../components/Layout";

const toastTitle = "Avaliação";

export default function AvaliacaoFormulario() {
	const questionnaireService = new QuestionnaireService();
	const questionnaireResponseService = new QuestionnaireResponseService();

	const navigate = useNavigate();
	const { handleToast } = useToast(toastTitle);
	const { user, geolocation } = useAuth();
	const { questionnaireId, id } = useParams();

	const [formStatus, setFormStatus] = useState(false);
	const [formNavigationStatus, setFormNavigationStatus] = useState(false);
	const [formSaving, setFormSaving] = useState(false);
	const [formStage, setFormStage] = useState<{ grupo: number | null; pergunta: number | null }>({ grupo: null, pergunta: null });
	const [formRespostas, setFormRespostas] = useState<RespostaType[]>([]);
	const formNextRef = useRef(false);

	const { data, isLoading, isFetching, isFetched, isRefetching } = useQuery<QuestionnaireGetResponse>(["avaliacao", questionnaireId], () => fetchData(Number(questionnaireId)), {
		refetchOnWindowFocus: false,
	});
	const queryResponse = useQuery<QuestionnaireResponseEntity>(["avaliacaoResposta", id], () => fetchDataResponse(Number(id)), {
		enabled: id !== undefined && isFetched,
		refetchOnWindowFocus: false,
	});

	const mutationPost = useMutation(mutatePost, { onSuccess: mutatePostSuccess, onError: mutateError });
	const mutationPut = useMutation(mutatePut, { onSuccess: mutatePutSuccess, onError: mutateError });

	useEffect(() => {
		if (formNextRef.current === true) {
			formNextRef.current = false;
			handleFormNext();
		}
	}, [formRespostas]);

	async function fetchData(id: number) {
		let resp = await questionnaireService.get(id);
		return resp.data;
	}

	async function fetchDataResponse(id: number) {
		let resp = await questionnaireResponseService.get(id);

		for (let i in resp.data.questionResponses) {
			let questionResponse = resp.data.questionResponses[i];

			for (let j in data?.questionGroups) {
				let questionGroup = data?.questionGroups[Number(j)];

				for (let k in questionGroup.questions) {
					let question = questionGroup.questions[Number(k)];

					if (question.id === questionResponse.questionId) {
						if (question.type === PerguntaTipo.image || question.allowImage) {
							let response = resp.data.questionResponses[i] as RespostaType;
							if (response.files) {
								for (let i in response.files) {
									try {
										let file = response.files[i];
										let fileResp = await axios.get(file.fileUrl!, { responseType: "blob" });
										let fileData = URL.createObjectURL(fileResp.data);
										file.data = fileData;
										response.files[i] = file;
									} catch (e) {}
								}
								resp.data.questionResponses[i] = response;
							}
						}
					}
				}
			}

			if (questionResponse.optionResponses) {
				for (let j in questionResponse.optionResponses) {
					let optionResponse = questionResponse.optionResponses[j];
					let index = 0;
					for (let k in data?.questionGroups) {
						let questionGroup = data?.questionGroups[Number(k)];

						for (let l in questionGroup.questions) {
							let question = questionGroup.questions[l];

							if (question.id === questionResponse.questionId) {
								for (let m in question.optionGroups) {
									let optionGroup = question.optionGroups[m];

									if (
										optionGroup.options.some((option) => {
											return option.id === optionResponse.optionId;
										})
									) {
										index = Number(m);
									}
								}
							}
						}
					}
					resp.data.questionResponses[i].optionResponses![j].index = index;
				}
			}
		}
		setFormRespostas(resp.data.questionResponses);

		return resp.data;
	}

	async function mutatePost(data: QuestionnaireResponsePostRequest) {
		setFormSaving(true);
		return await questionnaireResponseService.post(Number(questionnaireId), data);
	}

	function mutatePostSuccess(resp: AxiosResponse<QuestionnaireResponsePostResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		navigate("/avaliacaoDetalhes/" + resp.data.id);
		setFormStatus(false);
		setFormSaving(false);
	}

	async function mutatePut(data: QuestionnaireResponsePutRequest) {
		setFormSaving(true);
		return await questionnaireResponseService.put(Number(id), data);
	}

	function mutatePutSuccess(resp: AxiosResponse<QuestionnaireResponsePutResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		navigate("/avaliacaoDetalhes/" + resp.data.id);
		setFormStatus(false);
		setFormSaving(false);
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setFormSaving(false);
	}

	function handleFormPrevious() {
		setFormNavigationStatus(true);
		if (formStage.grupo === null) {
			return;
		}
		let anterior: any;
		if (formStage.pergunta === null) {
			if (formStage.grupo === 0) {
				anterior = { grupo: null, pergunta: null };
			} else {
				anterior = handleFormDependenciesPrevious(formStage.grupo! - 1, data!.questionGroups[formStage.grupo! - 1].questions.length - 1);
			}
		} else if (formStage.pergunta === 0) {
			anterior = { grupo: formStage.grupo, pergunta: null };
		} else {
			anterior = handleFormDependenciesPrevious(formStage.grupo, formStage.pergunta! - 1);
		}
		setFormStage({ grupo: anterior.grupo, pergunta: anterior.pergunta });
		setFormNavigationStatus(false);
	}

	function handleFormDependenciesPrevious(
		grupoIndex: number,
		perguntaIndex: number
	): {
		grupo: number | null;
		pergunta: number | null;
	} {
		let grupo = data!.questionGroups[grupoIndex];

		let dependenciesCheck = grupo.questions.some((question) => {
			if (question.dependencyGroups) {
				return question.dependencyGroups.every((dependencyGroup) => {
					if (dependencyGroup.dependencies) {
						return dependencyGroup.dependencies.some((dependency) => {
							return formRespostas.some((resposta) => {
								return resposta.optionResponses?.some((response) => {
									return response.optionId === dependency.optionId;
								});
							});
						});
					}
					return true;
				});
			}
			return true;
		});

		if (!dependenciesCheck) {
			if (perguntaIndex === null) {
				if (grupoIndex === 0) {
					return { grupo: null, pergunta: null };
				} else {
					return handleFormDependenciesPrevious(grupoIndex - 1, data!.questionGroups[grupoIndex - 1].questions.length - 1);
				}
			} else if (perguntaIndex === 0) {
				return handleFormDependenciesPrevious(grupoIndex - 1, data!.questionGroups[grupoIndex - 1].questions.length - 1);
			} else {
				return handleFormDependenciesPrevious(grupoIndex, perguntaIndex - 1);
			}
		}

		return {
			grupo: grupoIndex,
			pergunta: perguntaIndex,
		};
	}

	async function handleFormNext() {
		setFormNavigationStatus(true);
		let respostas = [...formRespostas];
		if (data && formStage.grupo !== null && formStage.pergunta !== null) {
			let pergunta = data.questionGroups[formStage.grupo].questions[formStage.pergunta];
			if (pergunta.type === PerguntaTipo.file || pergunta.type === PerguntaTipo.image || (pergunta.type === PerguntaTipo.parametro && pergunta.allowImage)) {
				let respostaIndex = respostas.findIndex((item) => {
					return item.questionId === pergunta.id;
				});
				if (respostaIndex >= 0) {
					let resposta = respostas[respostaIndex];
					if (resposta && resposta.files) {
						let files = resposta.files;
						for (let i in resposta.files) {
							let file = resposta.files[i];
							if (file.id === undefined && file.blob) {
								try {
									const formData = new FormData() as any;
									formData.append("file", file.blob);

									let respFile = await questionnaireResponseService.postQuestionFile(formData);

									file.fileId = respFile.data;
									resposta.files[i] = file;
								} catch (_) {}
							} else if (file.id === undefined) {
								files.splice(Number(i), 1);
							}
						}
						resposta.files = files;
					}
					respostas[respostaIndex] = resposta;
					setFormRespostas(respostas);
				}
			}
		}

		let proxima = handleFormDependenciesNext(formStage.grupo!, formStage.pergunta !== null ? formStage.pergunta + 1 : 0, respostas);

		if (proxima.grupo === null && proxima.pergunta === null) {
			setFormStatus(true);
		}

		setFormStage({ grupo: proxima.grupo, pergunta: proxima.pergunta });
		setFormNavigationStatus(false);
	}

	function handleFormDependenciesNext(
		grupoIndex: number,
		perguntaIndex: number,
		respostas: RespostaType[]
	): {
		grupo: number | null;
		pergunta: number | null;
	} {
		let grupo = data!.questionGroups[grupoIndex];
		if (!grupo) {
			return {
				grupo: null,
				pergunta: null,
			};
		}

		let pergunta = grupo.questions[perguntaIndex];
		if (!pergunta) {
			let resp = handleFormDependenciesNext(grupoIndex + 1, 0, respostas);
			if (resp.grupo !== null && resp.pergunta !== null) {
				return {
					grupo: resp.grupo,
					pergunta: null,
				};
			}
			return resp;
		}

		let dependenciesCheck = grupo.questions.some((question) => {
			if (question.dependencyGroups) {
				return question.dependencyGroups.every((dependencyGroup) => {
					if (dependencyGroup.dependencies) {
						return dependencyGroup.dependencies.some((dependency) => {
							return respostas.some((resposta) => {
								return resposta.optionResponses?.some((response) => {
									return response.optionId === dependency.optionId;
								});
							});
						});
					}
					return true;
				});
			}
			return true;
		});

		if (!dependenciesCheck) {
			return handleFormDependenciesNext(grupoIndex, perguntaIndex + 1, respostas);
		}

		if (pergunta) {
			let dependenciesCheckQuestion = pergunta.dependencyGroups.every((dependencyGroup) => {
				if (dependencyGroup.dependencies) {
					return dependencyGroup.dependencies.some((dependency) => {
						return respostas.some((resposta) => {
							return resposta.optionResponses?.some((response) => {
								return response.optionId === dependency.optionId;
							});
						});
					});
				}
				return true;
			});

			if (!dependenciesCheckQuestion) {
				return handleFormDependenciesNext(grupoIndex, perguntaIndex + 1, respostas);
			}
		}

		return {
			grupo: grupoIndex,
			pergunta: perguntaIndex,
		};
	}

	function handleQuestionGroupDependenciesCheck(grupo: QuestionGroupEntity) {
		return grupo.questions.some((question) => {
			if (question.dependencyGroups) {
				return question.dependencyGroups.every((dependencyGroup) => {
					if (dependencyGroup.dependencies) {
						return dependencyGroup.dependencies.some((dependency) => {
							return formRespostas.some((resposta) => {
								return resposta.optionResponses?.some((response) => {
									return response.optionId === dependency.optionId;
								});
							});
						});
					}
					return true;
				});
			}
			return true;
		});
	}

	function handleQuestionDependenciesCheck(question: QuestionEntity) {
		return question.dependencyGroups.every((dependencyGroup) => {
			if (dependencyGroup.dependencies) {
				return dependencyGroup.dependencies.some((dependency) => {
					return formRespostas.some((resposta) => {
						return resposta.optionResponses?.some((response) => {
							return response.optionId === dependency.optionId;
						});
					});
				});
			}
			return true;
		});
	}

	function handleFormText(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
	}

	function handleFormNumber(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
	}

	function handleFormTextarea(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
	}

	function handleFormRadio(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
		if (value.optionResponses?.length === perguntaAtual!.optionGroups?.length) {
			// handleFormNext();
			formNextRef.current = true;
		}
	}

	function handleFormCheck(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
	}

	function handleFormObjective(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
		// handleFormNext();
		formNextRef.current = true;
	}

	function handleFormGeoChange(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
	}

	function handleFormParametro(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return Number(item.questionId) === Number(value.questionId);
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
		if (
			value.optionResponses?.length === perguntaAtual!.optionGroups?.length &&
			(perguntaAtual?.allowImage === false || (perguntaAtual?.allowImage === true && value.files && value.files[0].blob))
		) {
			// handleFormNext();
			formNextRef.current = true;
		}
	}

	function handleFormDate(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
	}

	function handleFormFileChange(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
		if (value.files?.length === perguntaAtual!.maxAnswers) {
			// handleFormNext();
			formNextRef.current = true;
		}
	}

	function handleFormImageChange(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
		if (value.files?.length === perguntaAtual!.maxAnswers) {
			// handleFormNext();
			formNextRef.current = true;
		}
	}

	let grupoAtual = null;
	if (formStage.grupo !== null && data?.questionGroups[formStage.grupo]) {
		grupoAtual = data.questionGroups[formStage.grupo];
	}
	let perguntaAtual: QuestionEntity | null = null;
	let perguntaAtualResposta: RespostaType | null = null;
	let perguntaAtualNext = true;
	if (formStage.grupo !== null && formStage.pergunta !== null && data?.questionGroups[formStage.grupo].questions[formStage.pergunta]) {
		perguntaAtual = data.questionGroups[formStage.grupo].questions[formStage.pergunta];
		let perguntaId = data.questionGroups[formStage.grupo].questions[formStage.pergunta].id;
		perguntaAtualResposta =
			formRespostas.find((item) => {
				return item.questionId === perguntaId;
			}) ?? null;
		if (perguntaAtual.required) {
			perguntaAtualNext = false;
			if (perguntaAtualResposta) {
				perguntaAtualNext = true;
				if (perguntaAtualResposta.optionResponses && perguntaAtual.optionGroups) {
					perguntaAtualNext = perguntaAtualResposta.optionResponses.length === perguntaAtual.optionGroups.length;
					if (perguntaAtual.maxAnswers) {
						perguntaAtualNext = perguntaAtualResposta.optionResponses.length > 0;
					}
				}
			}
		}
	}
	console.log(formRespostas);
	return (
		<Layout sidebar={true}>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Link to={"/avaliacoes"} className="d-flex align-items-center text-decoration-none">
					<Icon path={mdiChevronLeft} size={1} className="me-1" /> <Icon path={mdiBookOutline} size={1} className="me-1" /> Formulário de Avaliação
				</Link>
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
			</h5>

			<Form
				onSubmit={(e) => {
					e.preventDefault();
					if (id) {
						mutationPut.mutate({
							geoLocation: {
								latitude: geolocation?.coords.latitude ?? 0,
								longitude: geolocation?.coords.longitude ?? 0,
							},
							personId: user!.usuario.id,
							questionResponses: formRespostas.map((resposta) => {
								let files = resposta.files?.filter((item) => item !== undefined) ?? [];
								return {
									id: resposta.id,
									questionId: resposta.questionId,
									value: resposta.value,
									text: resposta.text,
									complement: resposta.complement,
									geoLocation: resposta.geoLocation,
									optionResponses: resposta.optionResponses ?? [],
									files: files,
								};
							}),
						});
					} else {
						mutationPost.mutate({
							geoLocation: {
								latitude: geolocation?.coords.latitude ?? 0,
								longitude: geolocation?.coords.longitude ?? 0,
							},
							personId: user!.usuario.id,
							questionResponses: formRespostas.map((resposta) => {
								let files = resposta.files?.filter((item) => item !== undefined) ?? [];
								return {
									id: resposta.id,
									questionId: resposta.questionId,
									value: resposta.value,
									text: resposta.text,
									complement: resposta.complement,
									geoLocation: resposta.geoLocation,
									optionResponses: resposta.optionResponses ?? [],
									files: files,
								};
							}),
						});
					}
				}}
			>
				{/* FORMULÁRIO */}
				{!formStatus && (
					<>
						{/* AVALIAÇÃO */}
						{grupoAtual === null && (
							<Row className="mb-4">
								<Col md={12}>
									<Card className="mb-4 rounded-3 shadow-sm">
										<Card.Body className="d-flex flex-column justify-content-center px-5 pb-4">
											<div className={`h2 mb-4 text-center ${isLoading ? "skeleton skeleton-text skeleton-text-title skeleton-center" : ""}`}>
												{isLoading ? "..." : data?.title}
											</div>
											<div className={`fw-light text-center fs-5 ${isLoading ? "skeleton skeleton-text skeleton-text-content skeleton-center" : ""}`}>
												{isLoading ? (
													<>
														<br />
														<br />
														<br />
														<br />
													</>
												) : (
													data?.purpose
												)}
											</div>
										</Card.Body>
									</Card>
									{!isLoading && !queryResponse.isLoading ? (
										<div className="d-flex justify-content-center">
											<Button
												size="lg"
												className="d-flex justify-content-center align-items-center gap-3 text-uppercase shadow-sm"
												onClick={() => {
													setFormStage({ grupo: 0, pergunta: null });
												}}
												style={{ width: 180 }}
											>
												{id ? "Editar" : "Começar"}
												<Icon path={mdiRayStartVertexEnd} size={1} />
											</Button>
										</div>
									) : (
										<div className="d-flex align-items-center justify-content-center text-muted gap-3 fs-5 p-2">
											<Spinner />
											Carregando {id !== undefined ? "Respostas" : ""}
										</div>
									)}
								</Col>
							</Row>
						)}

						{/* AVALIAÇÃO GRUPO / PERGUNTA */}
						{grupoAtual !== null && (
							<Row className="mb-4">
								{/* AVALIAÇÃO CABEÇALHO */}
								<Col md={12}>
									<Card className="mb-4 rounded-3">
										<Card.Body
											className="h2 text-center p-3 mb-0"
											onClick={() => {
												setFormStage({ grupo: null, pergunta: null });
											}}
											style={{ cursor: "pointer" }}
										>
											{data?.title}
										</Card.Body>
									</Card>
								</Col>

								{/* SUMARIO */}
								<Col md={3}>
									<Card className="mb-4 rounded-3 position-sticky overflow-auto" style={{ top: "0.5rem", maxHeight: "calc(100dvh - 1rem)" }}>
										<Card.Body className="d-flex flex-column">
											{data?.questionGroups &&
												data?.questionGroups
													.sort((a, b) => {
														return a.order - b.order;
													})
													.map((questionGroup, questionGroupIndex) => {
														let dependenciesCheck = handleQuestionGroupDependenciesCheck(questionGroup);
														if (!dependenciesCheck) {
															return null;
														}

														let response = questionGroup.questions.some((question) => {
															return formRespostas.some((resposta) => {
																return resposta.questionId === question.id;
															});
														});
														let active = questionGroupIndex === formStage.grupo;
														let enabled = questionGroupIndex < formStage.grupo! || response;
														let icon = mdiCircleOutline;
														let text = "text-gray-500";
														if (active) {
															icon = mdiCircleSlice8;
															text = "";
														} else if (enabled) {
															icon = mdiCheckCircle;
															text = "";
														}

														return (
															<div key={questionGroupIndex} className="d-flex flex-column">
																<div
																	className={`d-flex align-items-center p-1 rounded-3 text-decoration-none ${active ? "bg-nav-active" : ""} ${text}`}
																	onClick={() => {
																		setFormStage({ grupo: questionGroupIndex, pergunta: null });
																	}}
																	style={{ cursor: "pointer" }}
																>
																	<Icon path={icon} size={0.8} style={{ color: questionGroup.color }} className="me-1" />
																	<div style={{ flexBasis: 0, flexGrow: 1 }}>{questionGroup.title}</div>
																</div>
																{questionGroup.questions
																	.sort((a, b) => {
																		return a.order - b.order;
																	})
																	.map((question, questionIndex) => {
																		let dependenciesCheck = handleQuestionDependenciesCheck(question);
																		if (!dependenciesCheck) {
																			return null;
																		}

																		let response = formRespostas.some((resposta) => {
																			return resposta.questionId === question.id;
																		});
																		let active = questionGroupIndex === formStage.grupo && questionIndex === formStage.pergunta;
																		let enabled =
																			questionGroupIndex < formStage.grupo! ||
																			(questionGroupIndex === formStage.grupo! && questionIndex <= formStage.pergunta!) ||
																			response;
																		let icon = mdiCircleOutline;
																		let text = "text-gray-500";
																		if (active) {
																			icon = mdiCircleSlice8;
																			text = "";
																		} else if (enabled) {
																			icon = mdiCheckCircle;
																			text = "";
																		}

																		return (
																			<div
																				key={questionIndex}
																				className={`d-flex align-items-center fw-light small p-1 rounded-3 text-decoration-none ${
																					active ? "bg-nav-active" : ""
																				} ${text}`}
																				onClick={() => {
																					setFormStage({ grupo: questionGroupIndex, pergunta: questionIndex });
																				}}
																				style={{ cursor: "pointer" }}
																			>
																				<Icon path={icon} size={0.8} style={{ color: questionGroup.color }} className="me-1" />
																				<div style={{ flexBasis: 0, flexGrow: 1 }}>{question.title}</div>
																			</div>
																		);
																	})}
															</div>
														);
													})}
										</Card.Body>
									</Card>
								</Col>

								{/* GRUPO */}
								{perguntaAtual === null && (
									<Col>
										<Card className="mb-4 rounded-3 shadow-sm" style={{ borderWidth: 4, borderStyle: "solid", borderColor: grupoAtual.color }}>
											<Card.Body className="d-flex flex-column justify-content-center p-5">
												<div className="h2 mb-4 text-center">{grupoAtual.title}</div>
												<div className="fw-light text-center text-muted mb-3">{grupoAtual.text}</div>
											</Card.Body>
										</Card>
										<div className="d-flex justify-content-center">
											<ButtonGroup size="lg" className="shadow-sm">
												<Button
													variant="light"
													className="d-flex justify-content-center align-items-center gap-3 text-uppercase"
													onClick={handleFormPrevious}
													disabled={formNavigationStatus}
													style={{ width: 180 }}
												>
													<Icon path={mdiRayStartArrow} size={1} style={{ transform: "scaleX(-1)" }} />
													Voltar
												</Button>
												<Button
													className="d-flex justify-content-center align-items-center gap-3 text-uppercase"
													onClick={handleFormNext}
													disabled={formNavigationStatus}
													style={{ width: 180 }}
												>
													Continuar
													<Icon path={mdiRayStartArrow} size={1} />
												</Button>
											</ButtonGroup>
										</div>
									</Col>
								)}

								{/* PERGUNTA */}
								{perguntaAtual !== null && (
									<Col>
										{/* PERGUNTA */}
										<Card className="mb-4 rounded-3 shadow-sm" style={{ borderTopWidth: 4, borderTopStyle: "solid", borderTopColor: grupoAtual.color }}>
											<Card.Body className="p-4">
												<div className="d-flex justify-content-center align-items-center h4 mb-2 text-center fw-normal">
													{perguntaAtual.title}
													{perguntaAtual.required === true && (
														<OverlayTrigger overlay={<Tooltip>É obrigatório responder</Tooltip>}>
															<div className="d-flex justify-content-center">
																<Icon path={mdiAsterisk} size={1} />
															</div>
														</OverlayTrigger>
													)}
												</div>
												<div className="fw-light text-center text-muted mb-3">{perguntaAtual.text}</div>
												{/* CAMPO TEXT */}
												{perguntaAtual.type === PerguntaTipo.text && (
													<FormText
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormText(value);
														}}
													/>
												)}
												{/* CAMPO TEXTAREA */}
												{perguntaAtual.type === PerguntaTipo.textarea && (
													<FormTextarea
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormTextarea(value);
														}}
													/>
												)}
												{/* CAMPO NUMBER */}
												{perguntaAtual.type === PerguntaTipo.number && (
													<FormNumber
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormNumber(value);
														}}
													/>
												)}
												{/* CAMPO RADIO */}
												{perguntaAtual.type === PerguntaTipo.radio && (
													<FormRadio
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormRadio(value);
														}}
													/>
												)}
												{/* CAMPO CHECKBOX */}
												{perguntaAtual.type === PerguntaTipo.checkbox && (
													<FormCheck
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormCheck(value);
														}}
													/>
												)}
												{/* CAMPO select */}

												{/* CAMPO DATE */}
												{perguntaAtual.type === PerguntaTipo.date && (
													<FormDate
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormDate(value);
														}}
													/>
												)}
												{/* CAMPO FILE */}
												{perguntaAtual.type === PerguntaTipo.file && (
													<FormFile
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormFileChange(value);
														}}
													/>
												)}
												{/* CAMPO IMAGE */}
												{perguntaAtual.type === PerguntaTipo.image && (
													<FormImage
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormImageChange(value);
														}}
													/>
												)}
												{/* CAMPO OBJECTIVE */}
												{perguntaAtual.type === PerguntaTipo.objective && (
													<FormObjective
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormObjective(value);
														}}
													/>
												)}
												{/* CAMPO GEO */}
												{perguntaAtual.type === PerguntaTipo.geo && (
													<FormGeo
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormGeoChange(value);
														}}
													/>
												)}
												{/* CAMPO PARAMETRO */}
												{perguntaAtual.type === PerguntaTipo.parametro && (
													<FormParametro
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormParametro(value);
														}}
													/>
												)}
											</Card.Body>
										</Card>
										<div className="d-flex justify-content-center">
											<ButtonGroup size="lg" className="shadow-sm">
												<Button
													variant="light"
													className="d-flex justify-content-center align-items-center gap-3 text-uppercase"
													onClick={handleFormPrevious}
													disabled={formNavigationStatus}
													style={{ width: 180 }}
												>
													<Icon path={mdiRayStartArrow} size={1} style={{ transform: "scaleX(-1)" }} />
													Anterior
												</Button>
												<Button
													className="d-flex justify-content-center align-items-center gap-3 text-uppercase"
													onClick={handleFormNext}
													disabled={!perguntaAtualNext || formNavigationStatus}
													style={{ width: 180 }}
												>
													Próximo
													<Icon path={mdiRayStartArrow} size={1} />
												</Button>
											</ButtonGroup>
										</div>
									</Col>
								)}
							</Row>
						)}
					</>
				)}

				{/* SALVAR */}
				{formStatus && (
					<Row className="mb-4">
						<Col md={12}>
							<Card className="mb-4 rounded-3">
								<Card.Body
									className="h2 text-center p-3 mb-0"
									onClick={() => {
										setFormStage({ grupo: null, pergunta: null });
										setFormStatus(false);
									}}
									style={{ cursor: "pointer" }}
								>
									{data?.title}
								</Card.Body>
							</Card>
						</Col>

						<Col md={12}>
							<Card className="mb-4 rounded-3 shadow-sm">
								<Card.Body className="d-flex flex-column justify-content-center align-items-center text-center px-5 pb-4">
									{!formSaving && (
										<>
											<Icon path={mdiRayStartEnd} size={5} />
											<div className="fw-light text-center fs-4">Avaliação concluída</div>
										</>
									)}
									{formSaving && (
										<>
											<div className="d-flex justify-content-center align-items-center" style={{ width: 120, height: 120 }}>
												<Spinner style={{ width: 100, height: 100, borderWidth: 10 }} />
											</div>
											<div className="fw-light text-center fs-4">Salvando avaliação</div>
										</>
									)}
								</Card.Body>
							</Card>
							{!formSaving && (
								<div className="d-flex justify-content-center">
									<Button size="lg" type="submit" className="d-flex gap-2 align-items-center text-uppercase px-5 rounded-3 shadow-sm">
										Salvar
										<Icon path={mdiCheck} size={1} />
									</Button>
								</div>
							)}
						</Col>
					</Row>
				)}
			</Form>
		</Layout>
	);
}
