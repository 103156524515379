export const SelectStyle = {
	control: (styles: any, state: any) => {
		return {
			...styles,
			borderWidth: 1,
			borderStyle: "solid",
			borderColor: state.isFocused ? "#86b7fe" : "#ced4da",
			boxShadow: state.isFocused ? "0 0 0 0.25rem rgb(13 110 253 / 25%)" : "none",
			borderRadius: "0.375rem",
			backgroundColor: state.isDisabled ? "#e9ecef" : "white",
		};
	},
	singleValue: (styles: any, state: any) => {
		return {
			...styles,
			color: "#212529",
		};
	},
	multiValue: (styles: any, state: any) => {
		return {
			...styles,
			whiteSpace: "break-spaces",
		};
	},
	multiValueLabel: (styles: any, state: any) => {
		return {
			...styles,
			whiteSpace: "break-spaces",
		};
	},
};

export const SelectGroupStyle = {
	container: (styles: any, state: any) => {
		return {
			...styles,
			flex: 1,
			display: "flex",
		};
	},
	control: (styles: any, state: any) => {
		return {
			...styles,
			flex: 1,
			borderWidth: 1,
			borderStyle: "solid",
			borderColor: state.isFocused ? "#86b7fe" : "#ced4da",
			boxShadow: state.isFocused ? "0 0 0 0.25rem rgb(13 110 253 / 25%)" : "none",
			borderRadius: "0.375rem",
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			backgroundColor: state.isDisabled ? "#e9ecef" : "white",
		};
	},
	singleValue: (styles: any, state: any) => {
		return {
			...styles,
			color: "#212529",
		};
	},
};

export const SelectOptions = {
	noOptionsMessage: (v: any) => {
		return v.inputValue.length === 0 ? "Digite para buscar" : "Nenhuma opção disponível";
	},
};
