import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiOfficeBuildingOutline } from "@mdi/js";

import { EscalaComprometimento, EscalaRisco, EscalaVunerabilidade, ModuleEntity, ModulePermissionEntity } from "../../config/defines";
import { escalaCalculo, modulePermissionCheck } from "../../config/utils";
import { useAuth } from "../../context/AuthContext";
import { QuestionnaireResponseGetSummaryResponse } from "../../entities/QuestionnaireResponseEntity";
import QuestionnaireResponseService from "../../services/QuestionnaireResponseService";

import ListData from "../../components/ListData";
import Layout from "../../components/Layout";

export default function AvaliacaoDetalhes() {
	const questionnaireResponseService = new QuestionnaireResponseService();

	const navigate = useNavigate();
	const { user } = useAuth();
	const { id } = useParams();

	const { data, isLoading, isFetching, isRefetching } = useQuery<QuestionnaireResponseGetSummaryResponse>(["avaliacaoSumario", id], () => fetchData(id), { enabled: id !== undefined });

	async function fetchData(id: any) {
		let resp = await questionnaireResponseService.getSummary(id);
		return resp.data;
	}

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Link to={"/avaliacoes"} className="d-flex align-items-center text-decoration-none">
					<Icon path={mdiChevronLeft} size={1} className="me-1" /> <Icon path={mdiOfficeBuildingOutline} size={1} className="me-1" /> Detalhes da Avaliação
				</Link>
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					{modulePermissionCheck(user!, ModuleEntity.avaliacoes, ModulePermissionEntity.editar) && (
						<div className="d-flex gap-2">
							<Button
								variant="light"
								className="rounded-3 shadow-sm"
								onClick={() => {
									window.open(process.env.REACT_APP_API_BASE_URL + `api/v1/QuestionnaireResponse/${id}/Report`);
								}}
							>
								Visualizar Laudo
							</Button>
							<Button
								variant="light"
								className="rounded-3 shadow-sm"
								onClick={() => {
									navigate(`/avaliacaoFormulario/${data?.questionnaireId}/${id}`);
								}}
							>
								Editar Informações
							</Button>
						</div>
					)}
				</div>
			</h5>

			<Card className="mb-3">
				<Card.Body className="d-flex flex-column gap-2">
					<ListData>
						<ListData.Content>
							<ListData.Item>
								<ListData.Title>Identificação</ListData.Title>
								<ListData.Text skeleton={isLoading}>#{data?.id}&nbsp;</ListData.Text>
							</ListData.Item>
							<ListData.Item>
								<ListData.Title>Avaliação</ListData.Title>
								<ListData.Text skeleton={isLoading}>
									{data?.questionnaireName} <span className="text-muted">#{data?.questionnaireId}</span>&nbsp;
								</ListData.Text>
							</ListData.Item>
						</ListData.Content>
						<ListData.Content>
							<ListData.Item>
								<ListData.Title>Usuário</ListData.Title>
								<ListData.Text skeleton={isLoading}>{data?.person.name}&nbsp;</ListData.Text>
							</ListData.Item>
							<ListData.Item>
								<ListData.Title>Data</ListData.Title>
								<ListData.Text skeleton={isLoading}>{data?.responseDate}&nbsp;</ListData.Text>
							</ListData.Item>
						</ListData.Content>
						<ListData.Content>
							<ListData.Item>
								<ListData.Title>Localização</ListData.Title>
								<ListData.Text skeleton={isLoading}>
									{data?.geoLocation.latitude}, {data?.geoLocation.longitude}&nbsp;
								</ListData.Text>
							</ListData.Item>
						</ListData.Content>
					</ListData>
				</Card.Body>
			</Card>
			{data?.metrics.map((metric, metricIndex) => {
				let escalaBase = EscalaRisco;
				if (metricIndex === 1) {
					escalaBase = EscalaVunerabilidade;
				}
				if (metricIndex === 2) {
					escalaBase = EscalaComprometimento;
				}
				let escala = escalaCalculo(escalaBase, metric.value);

				return (
					<Card className="mb-3" key={metricIndex}>
						<Card.Body className="d-flex flex-column gap-3">
							<ListData>
								<ListData.Content>
									<ListData.Item>
										<ListData.Title>{metric.title}</ListData.Title>
										<ListData.Text skeleton={isLoading}>{metric.value}</ListData.Text>
									</ListData.Item>
									<ListData.Item>
										<ListData.Title>Descrição</ListData.Title>
										<ListData.Text skeleton={isLoading}>
											{escala.index}. {escala.data.titulo}
										</ListData.Text>
									</ListData.Item>
								</ListData.Content>
							</ListData>
							<div className="d-flex position-relative m-2 rounded-3">
								<div className="d-flex flex-fill rounded-3 overflow-hidden position-relative" style={{ height: 50, border: "2px solid black" }}>
									{Array.from({ length: 10 }, (_, index) => {
										return <div className="position-absolute" style={{ top: 0, bottom: 0, left: `calc(${index * 10}% - 2px)`, borderLeft: "2px dashed black", zIndex: 2 }} />;
									})}
									<div className="flex-fill" style={{ background: "#34cc33", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#34cc33", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#339965", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#339965", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#ffff00", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#ffff00", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#ffbf00", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#ffbf00", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#c00201", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#c00201", height: "100%", opacity: 1 }} />
								</div>
								<div className="position-absolute" style={{ top: -5, left: `${metric.value}%`, height: 60, transform: "translateX(-25%)", zIndex: 3 }}>
									<div
										className="d-flex align-items-center justify-content-center bg-white rounded-3 shadow fw-bold border border-black border-2 small"
										style={{ marginLeft: -17.5, width: 35, height: 60 }}
									>
										{metric.value}
									</div>
								</div>
							</div>
							<div className="fw-light">{escala.data.descricao}</div>
						</Card.Body>
					</Card>
				);
			})}

			{/* <ListData.Content>
							<ListData.Item>
								<ListData.Title>Vulnerabilidade Funcional</ListData.Title>
								<ListData.Text skeleton={isLoading}>100 - Funcionalidade Severamente Comprometida</ListData.Text>
								<div className="d-flex position-relative m-2 rounded-3">
									<div className="d-flex flex-fill rounded-3 overflow-hidden position-relative" style={{ height: 50, border: "2px solid black" }}>
										{Array.from({ length: 10 }, (_, index) => {
											return <div className="position-absolute" style={{ top: 0, bottom: 0, left: `calc(${index * 10}% - 2px)`, borderLeft: "2px dashed black", zIndex: 2 }} />;
										})}
										<div className="flex-fill" style={{ background: "#34cc33", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#34cc33", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#339965", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#339965", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#ffff00", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#ffff00", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#ffbf00", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#ffbf00", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#c00201", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#c00201", height: "100%", opacity: 1 }} />
									</div>
									<div className="position-absolute" style={{ top: -5, left: "100%", height: 60, transform: "translateX(-25%)", zIndex: 3 }}>
										<div
											className="d-flex align-items-center justify-content-center bg-white rounded-3 shadow fw-bold border border-black border-2 small"
											style={{ marginLeft: -17.5, width: 35, height: 60 }}
										>
											100
										</div>
									</div>
								</div>
							</ListData.Item>
						</ListData.Content>
						<ListData.Content>
							<ListData.Item>
								<ListData.Title>Índice de Comprometimento</ListData.Title>
								<ListData.Text skeleton={isLoading}>93 - Comprometimento Muito Alto</ListData.Text>
								<div className="d-flex position-relative m-2 rounded-3">
									<div className="d-flex flex-fill rounded-3 overflow-hidden position-relative" style={{ height: 50, border: "2px solid black" }}>
										{Array.from({ length: 10 }, (_, index) => {
											return <div className="position-absolute" style={{ top: 0, bottom: 0, left: `calc(${index * 10}% - 2px)`, borderLeft: "2px dashed black", zIndex: 2 }} />;
										})}
										<div className="flex-fill" style={{ background: "#34cc33", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#34cc33", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#339965", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#339965", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#ffff00", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#ffff00", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#ffbf00", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#ffbf00", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#c00201", height: "100%", opacity: 1 }} />
										<div className="flex-fill" style={{ background: "#c00201", height: "100%", opacity: 1 }} />
									</div>
									<div className="position-absolute" style={{ top: -5, left: "93%", height: 60, transform: "translateX(-25%)", zIndex: 3 }}>
										<div
											className="d-flex align-items-center justify-content-center bg-white rounded-3 shadow fw-bold border border-black border-2 small"
											style={{ marginLeft: -17.5, width: 35, height: 60 }}
										>
											93
										</div>
									</div>
								</div>
							</ListData.Item>
						</ListData.Content> */}

			{modulePermissionCheck(user!, ModuleEntity.avaliacoes, ModulePermissionEntity.editar) && (
				<div className="d-flex gap-2 mb-4">
					<Button
						variant="light"
						className="rounded-3 shadow-sm"
						onClick={() => {
							window.open(process.env.REACT_APP_API_BASE_URL + `api/v1/QuestionnaireResponse/${id}/Report`);
						}}
					>
						Visualizar Laudo
					</Button>
					<Button
						variant="light"
						className="rounded-3 shadow-sm"
						onClick={() => {
							navigate(`/avaliacaoFormulario/${data?.questionnaireId}/${id}`);
						}}
					>
						Editar Informações
					</Button>
				</div>
			)}
		</Layout>
	);
}
