import { Card, Spinner } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChartArc, mdiChartBox, mdiChartBoxOutline, mdiChevronRight, mdiQuadcopter } from "@mdi/js";
import ReactApexChart from "react-apexcharts";
import { EscalaRisco } from "../../config/defines";

type DashboardCardProps = {
	title: string;
	options?: ApexCharts.ApexOptions | undefined;
	series?: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
	type?:
		| "area"
		| "line"
		| "bar"
		| "pie"
		| "donut"
		| "radialBar"
		| "scatter"
		| "bubble"
		| "heatmap"
		| "candlestick"
		| "boxPlot"
		| "radar"
		| "polarArea"
		| "rangeBar"
		| "rangeArea"
		| "treemap"
		| undefined;
	height?: string | number | undefined;
	loading?: boolean;
};

export default function DashboardChartCard(props: DashboardCardProps) {
	return (
		<Card className="border-0 shadow mb-4">
			<Card.Body className="p-4">
				<div className="d-flex align-items-center justify-content-between">
					<div className="fs-5 fw-lighter line-1">
						{props.title} {props.loading && <Spinner size="sm" className="ms-1" variant="secondary" />}
					</div>
					<Icon path={mdiChartBoxOutline} size={1} className="float-end" />
				</div>
				<ReactApexChart options={props.options} series={props.series} type={props.type} height={props.height} />
			</Card.Body>
		</Card>
	);
}
