import { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Container, FloatingLabel, Form, Image, InputGroup, Row, Spinner } from "react-bootstrap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiEye, mdiEyeOff } from "@mdi/js";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import UserService from "../../services/UserService";

import Footer from "../../components/Footer";

const userService = new UserService();

export default function Login() {
	const { user, handleLogin } = useAuth();
	const { clearToast } = useToast();

	const location = useLocation();
	const navigate = useNavigate();

	const emailRef = useRef<HTMLInputElement>() as React.RefObject<HTMLInputElement>;
	const passwordRef = useRef<HTMLInputElement>() as React.RefObject<HTMLInputElement>;

	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const [stage, setStage] = useState("login");

	const [senhaExibir, setSenhaExibir] = useState(false);

	const [recuperarSenhaLoading, setRecuperarSenhaLoading] = useState(false);
	const [recuperarSenhaMessage, setRecuperarSenhaMessage] = useState("");
	const [recuperarSenhaEmail, setRecuperarSenhaEmail] = useState("");
	const [recuperarSenhaToken, setRecuperarSenhaToken] = useState("");

	const [recuperarSenhaCodigoLoading, setRecuperarSenhaCodigoLoading] = useState(false);
	const [recuperarSenhaCodigoMessage, setRecuperarSenhaCodigoMessage] = useState("");
	const [recuperarSenhaCodigo, setRecuperarSenhaCodigo] = useState("");
	const [recuperarSenhaCodigoNovaSenha, setRecuperarSenhaCodigoNovaSenha] = useState("");
	const [recuperarSenhaCodigoExibir, setRecuperarSenhaCodigoExibir] = useState(false);

	useEffect(() => {
		clearToast();
		// eslint-disable-next-line
	}, []);

	if (user) {
		return <Navigate to="/dashboard" state={{ from: location }} replace />;
	}

	async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		if (!emailRef.current || !passwordRef.current) {
			return false;
		}

		setLoading(true);
		setMessage("");

		let response = await handleLogin(emailRef.current.value, passwordRef.current.value);
		if (response === true) {
			navigate("/dashboard");
		} else {
			setLoading(false);
			setMessage(response);
		}
	}

	async function handleRecuperarSenhaSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		setRecuperarSenhaLoading(true);
		let resp = await userService.getRecoveryToken({ email: recuperarSenhaEmail });
		if (resp.status === 200) {
			setStage("recuperarSenhaCodigo");
			setRecuperarSenhaToken(resp.data.token);
		} else {
			setRecuperarSenhaMessage(resp.statusText);
		}
		setRecuperarSenhaLoading(false);
	}

	async function handleRecuperarSenhaCodigoSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		setRecuperarSenhaCodigoLoading(true);
		let respValidate = await userService.validateRecoveryToken({ code: recuperarSenhaCodigo, jwtToken: recuperarSenhaToken });
		if (respValidate.status === 200 && respValidate.data.isValid) {
			let respUpdate = await userService.updatePassword({ password: recuperarSenhaCodigoNovaSenha, token: respValidate.data.token });
			if (respUpdate.status === 200) {
				setStage("recuperarSenhaSucesso");
				setRecuperarSenhaCodigoMessage("Senha alterada com sucesso!");
			} else {
				setRecuperarSenhaCodigoMessage("Não foi possível realizar a operação");
			}
		} else {
			setRecuperarSenhaCodigoMessage("Não foi possível realizar a operação");
		}
		setRecuperarSenhaCodigoLoading(false);
	}

	return (
		<Container className="d-flex justify-content-center flex-column p-4 pb-0" style={{ minHeight: "100vh" }}>
			<Card className="p-2 shadow-lg bg-white rounded-3" style={{ height: 650 }}>
				<Card.Body>
					<Row style={{ height: "100%" }}>
						<Col lg={5}>
							<div className="p-5">
								<Image fluid className="pb-4" src={process.env.PUBLIC_URL + "/logo.webp"} style={{ width: "50%" }} />
								{/* LOGIN */}
								{stage === "login" && (
									<Form onSubmit={handleSubmit}>
										<Form.Text className="fs-3 mb-2 text-black" as="div">
											Acessar Plataforma
										</Form.Text>
										<Form.Text className="fs-6 mb-3 text-black" as="div">
											Informe os dados abaixo para acessar a plataforma
										</Form.Text>

										<FloatingLabel controlId="email" label="E-mail" className="mb-3">
											<Form.Control type="text" placeholder="Informe aqui o email" ref={emailRef} required />
										</FloatingLabel>

										<InputGroup className="mb-3">
											<FloatingLabel controlId="senha" label="Senha">
												<Form.Control type={senhaExibir ? "text" : "password"} placeholder="Informe aqui a senha" ref={passwordRef} required />
											</FloatingLabel>
											<Button
												variant="light"
												className="border"
												onClick={() => {
													setSenhaExibir(!senhaExibir);
												}}
											>
												<Icon path={senhaExibir ? mdiEye : mdiEyeOff} size={1} />
											</Button>
										</InputGroup>

										{message && (
											<Alert variant="danger" className="text-center">
												{message}
											</Alert>
										)}

										<Button type="submit" variant="primary" size="lg" className="w-100" disabled={loading}>
											{loading ? (
												<>
													<Spinner animation="border" size="sm" className="me-2" /> Acessando
												</>
											) : (
												"Acessar"
											)}
										</Button>

										<div className="d-flex align-items-center my-3">
											<hr className="flex-fill" />
											<div className="px-2">ou</div>
											<hr className="flex-fill" />
										</div>

										<Button
											type="button"
											variant="light"
											size="lg"
											className="w-100"
											onClick={() => {
												setStage("recuperarSenha");
											}}
										>
											Recuperar acesso
										</Button>
									</Form>
								)}
								{/* RECUPERAR SENHA */}
								{stage === "recuperarSenha" && (
									<Form onSubmit={handleRecuperarSenhaSubmit}>
										<Form.Text className="fs-3 mb-2 text-black" as="div">
											Recuperar Acesso
										</Form.Text>
										<Form.Text className="fs-6 mb-3 text-black" as="div">
											Informe os dados abaixo para recuperar o acesso
										</Form.Text>

										<FloatingLabel controlId="email" label="E-mail" className="mb-3">
											<Form.Control
												type="text"
												placeholder="Informe aqui o email"
												onChange={(event) => {
													setRecuperarSenhaEmail(event.target.value);
												}}
												required
											/>
										</FloatingLabel>

										{recuperarSenhaMessage && (
											<Alert variant="danger" className="text-center">
												{recuperarSenhaMessage}
											</Alert>
										)}

										<Button type="submit" variant="primary" size="lg" className="w-100 mb-3" disabled={recuperarSenhaLoading}>
											{recuperarSenhaLoading ? (
												<>
													<Spinner animation="border" size="sm" className="me-2" /> Recuperando
												</>
											) : (
												"Recuperar"
											)}
										</Button>

										<Button
											type="button"
											variant="light"
											size="lg"
											className="w-100"
											onClick={() => {
												setStage("login");
											}}
										>
											Cancelar
										</Button>
									</Form>
								)}

								{/* RECUPERAR SENHA - CODIGO */}
								{stage === "recuperarSenhaCodigo" && (
									<Form onSubmit={handleRecuperarSenhaCodigoSubmit}>
										<Form.Text className="fs-3 mb-2 text-black" as="div">
											Recuperar Acesso
										</Form.Text>
										<Form.Text className="fs-6 mb-3 text-black" as="div">
											Informe os dados abaixo para recuperar o acesso
										</Form.Text>

										<Alert variant="primary" className="text-center">
											Enviamos um código para <span className="text-underline">{recuperarSenhaEmail}</span>
										</Alert>

										<FloatingLabel controlId="recuperarSenhaCodigo" label="Código" className="mb-3 input-login">
											<Form.Control
												type="text"
												placeholder="Informe aqui o código"
												value={recuperarSenhaCodigo}
												onChange={(event) => {
													setRecuperarSenhaCodigo(event.target.value);
												}}
												required
											/>
										</FloatingLabel>

										<InputGroup className="mb-3">
											<FloatingLabel controlId="recuperarSenhaNovaSenha" label="Nova senha" className="input-login">
												<Form.Control
													type={recuperarSenhaCodigoExibir ? "text" : "password"}
													placeholder="Informe aqui a nova senha"
													value={recuperarSenhaCodigoNovaSenha}
													onChange={(event) => {
														setRecuperarSenhaCodigoNovaSenha(event.target.value);
													}}
													required
												/>
											</FloatingLabel>
											<Button
												variant="outline-secondary"
												onClick={() => {
													setRecuperarSenhaCodigoExibir(!recuperarSenhaCodigoExibir);
												}}
											>
												<Icon path={recuperarSenhaCodigoExibir ? mdiEye : mdiEyeOff} size={1} />
											</Button>
										</InputGroup>

										{recuperarSenhaCodigoMessage && (
											<Alert variant="danger" className="text-center">
												{recuperarSenhaCodigoMessage}
											</Alert>
										)}

										<Button type="submit" variant="primary" size="lg" className="w-100 mb-3" disabled={recuperarSenhaCodigoLoading}>
											{recuperarSenhaCodigoLoading ? (
												<>
													<Spinner animation="border" size="sm" className="me-2" /> Alterando senha
												</>
											) : (
												"Alterar senha"
											)}
										</Button>

										<Button
											type="button"
											variant="light"
											size="lg"
											className="w-100"
											onClick={() => {
												setStage("login");
											}}
										>
											Cancelar
										</Button>
									</Form>
								)}

								{/* RECUPERAR SENHA - SUCESSO */}
								{stage === "recuperarSenhaSucesso" && (
									<Form>
										<Form.Text className="fs-3 mb-2 text-black" as="div">
											Recuperar Acesso
										</Form.Text>
										<Form.Text className="fs-6 mb-3 text-black" as="div">
											Informe os dados abaixo para recuperar o acesso
										</Form.Text>

										{recuperarSenhaCodigoMessage && (
											<Alert variant="primary" className="text-center mb-3">
												{recuperarSenhaCodigoMessage}
											</Alert>
										)}

										<Button
											type="button"
											variant="primary"
											size="lg"
											className="w-100 mb-3"
											onClick={() => {
												setStage("login");
											}}
										>
											Acessar
										</Button>
									</Form>
								)}
							</div>
						</Col>
						<Col lg={7} className="d-none d-lg-block">
							<div className="rounded-3 overflow-hidden position-relative" style={{ height: "100%" }}>
								<video src={process.env.PUBLIC_URL + "login_background.mp4"} muted autoPlay playsInline loop style={{ objectFit: "cover", width: "100%", height: "100%" }} />
								<div
									className="d-flex align-items-center justify-content-center flex-column gap-4 p-4 position-absolute text-white"
									style={{ inset: 0, backgroundColor: "rgba(0,0,0,0.4)", backdropFilter: "blur(5px)" }}
								>
									<h2 className="text-center" style={{ textShadow: "0px 0px 10px rgba(0,0,0,0.5)" }}>
										Inspeções e Ensaios não-destrutivos que garantem a qualidade e durabilidade do empreendimento
									</h2>
									<div className="fs-5 text-center" style={{ textShadow: "0px 0px 10px rgba(0,0,0,0.5)" }}>
										ReHabi é uma ferramenta tecnológica desenvolvida para auxiliar na avaliação técnica de edificações residenciais afetadas por inundações.
										<br />O objetivo é fornecer à comunidade informações precisas, confiáveis e métricas para a tomada de decisões.
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Footer />
		</Container>
	);
}
