import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChevronRight, mdiHome, mdiViewDashboardOutline } from "@mdi/js";

import Layout from "../../components/Layout";
import ListCard from "../../components/ListCard";
import { useAuth } from "../../context/AuthContext";
import { screens } from "../../config/screens";
import DashboardChartCard from "../../components/DashboardChartCard";
import { EscalaComprometimento, EscalaRisco, EscalaVunerabilidade } from "../../config/defines";

export default function Dashboard() {
	const { user } = useAuth();
	const navigate = useNavigate();

	function handleClick(item: string) {
		navigate(item);
	}

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Icon path={mdiViewDashboardOutline} size={1} className="me-1" /> Dashboard
			</h5>

			<Row className="mb-4">
				<Col lg={4}>
					<DashboardChartCard
						title="Risco Estrutural"
						type="radar"
						series={[
							{
								name: "Porcentagem",
								data: [30, 32, 15, 2, 8],
							},
						]}
						options={{
							chart: {
								type: "radar",
								toolbar: {
									show: false,
								},
							},
							yaxis: {
								labels: {
									formatter: (value) => `${value}%`,
								},
							},
							xaxis: {
								categories: [
									[`1.${EscalaRisco[1].titulo}`],
									["2.", ...EscalaRisco[2].titulo.split(" ")],
									["3.", ...EscalaRisco[3].titulo.split(" ")],
									["4.", ...EscalaRisco[4].titulo.split(" ")],
									["5.", ...EscalaRisco[5].titulo.split(" ")],
								],
							},
							// dataLabels: {
							// 	enabled: true,
							// 	formatter: (value) => `${value}%`,
							// },
						}}
						height={300}
					/>
				</Col>
				<Col lg={4}>
					<DashboardChartCard
						title="Vulnerabilidade Funcional"
						type="radar"
						series={[
							{
								name: "Porcentagem",
								data: [35, 30, 15, 5, 10],
							},
						]}
						options={{
							chart: {
								type: "radar",
								toolbar: {
									show: false,
								},
							},
							yaxis: {
								labels: {
									formatter: (value) => `${value}%`,
								},
							},
							xaxis: {
								categories: [
									[`1.${EscalaVunerabilidade[1].titulo}`],
									["2.", ...EscalaVunerabilidade[2].titulo.split(" ")],
									["3.", ...EscalaVunerabilidade[3].titulo.split(" ")],
									["4.", ...EscalaVunerabilidade[4].titulo.split(" ")],
									["5.", ...EscalaVunerabilidade[5].titulo.split(" ")],
								],
							},
							// dataLabels: {
							// 	enabled: true,
							// 	formatter: (value) => `${value}%`,
							// },
						}}
						height={300}
					/>
				</Col>
				<Col lg={4}>
					<DashboardChartCard
						title="Índice de Comprometimento"
						type="radar"
						series={[
							{
								name: "Porcentagem",
								data: [35, 30, 15, 5, 10],
							},
						]}
						options={{
							chart: {
								type: "radar",
								toolbar: {
									show: false,
								},
							},
							yaxis: {
								labels: {
									formatter: (value) => `${value}%`,
								},
							},
							xaxis: {
								categories: [
									[`1.${EscalaComprometimento[1].titulo}`],
									["2.", ...EscalaComprometimento[2].titulo.split(" ")],
									["3.", ...EscalaComprometimento[3].titulo.split(" ")],
									["4.", ...EscalaComprometimento[4].titulo.split(" ")],
									["5.", ...EscalaComprometimento[5].titulo.split(" ")],
								],
							},
							// dataLabels: {
							// 	enabled: true,
							// 	formatter: (value) => `${value}%`,
							// },
						}}
						height={300}
					/>
				</Col>
				<Col lg={12}>
					<DashboardChartCard
						title="Risco Estrutura x Tipologia"
						type="bar"
						series={[
							{
								name: "Parede estrutural (autoportante)",
								data: [44, 55, 41, 67, 22],
							},
							{
								name: "Madeira",
								data: [13, 23, 20, 8, 13],
							},
							{
								name: "Concreto Armado",
								data: [11, 17, 15, 15, 21],
							},
							{
								name: "Não identificado",
								data: [21, 7, 25, 13, 22],
							},
						]}
						options={{
							chart: {
								type: "bar",
								stacked: true,
								toolbar: {
									show: false,
								},
							},
							xaxis: {
								categories: [
									["1.", ...EscalaRisco[1].titulo.split(" ")],
									["2.", ...EscalaRisco[2].titulo.split(" ")],
									["3.", ...EscalaRisco[3].titulo.split(" ")],
									["4.", ...EscalaRisco[4].titulo.split(" ")],
									["5.", ...EscalaRisco[5].titulo.split(" ")],
								],
							},
							yaxis: {
								labels: {
									formatter: (value) => `${value}%`,
								},
							},
							dataLabels: {
								enabled: true,
								formatter: (value) => `${value}%`,
							},
							plotOptions: {
								bar: {
									borderRadius: 10,
								},
							},
							legend: {
								position: "right",
							},
							tooltip: {
								enabled: true,
								shared: true,
								intersect: false,
							},
						}}
						height={400}
					/>
				</Col>
				<Col lg={12}>
					<DashboardChartCard
						title="Vulnerabilidade Funcional x Tipologia"
						type="bar"
						series={[
							{
								name: "Parede estrutural (autoportante)",
								data: [44, 55, 41, 67, 22],
							},
							{
								name: "Madeira",
								data: [13, 23, 20, 8, 13],
							},
							{
								name: "Concreto Armado",
								data: [11, 17, 15, 15, 21],
							},
							{
								name: "Não identificado",
								data: [21, 7, 25, 13, 22],
							},
						]}
						options={{
							chart: {
								type: "bar",
								stacked: true,
								toolbar: {
									show: false,
								},
							},
							xaxis: {
								categories: [
									["1.", ...EscalaVunerabilidade[1].titulo.split(" ")],
									["2.", ...EscalaVunerabilidade[2].titulo.split(" ")],
									["3.", ...EscalaVunerabilidade[3].titulo.split(" ")],
									["4.", ...EscalaVunerabilidade[4].titulo.split(" ")],
									["5.", ...EscalaVunerabilidade[5].titulo.split(" ")],
								],
							},
							yaxis: {
								labels: {
									formatter: (value) => `${value}%`,
								},
							},
							dataLabels: {
								enabled: true,
								formatter: (value) => `${value}%`,
							},
							plotOptions: {
								bar: {
									borderRadius: 10,
								},
							},
							legend: {
								position: "right",
							},
							tooltip: {
								enabled: true,
								shared: true,
								intersect: false,
							},
						}}
						height={400}
					/>
				</Col>
				<Col lg={12}>
					<DashboardChartCard
						title="Índice de Comprometimento x Tipologia"
						type="bar"
						series={[
							{
								name: "Parede estrutural (autoportante)",
								data: [44, 55, 41, 67, 22],
							},
							{
								name: "Madeira",
								data: [13, 23, 20, 8, 13],
							},
							{
								name: "Concreto Armado",
								data: [11, 17, 15, 15, 21],
							},
							{
								name: "Não identificado",
								data: [21, 7, 25, 13, 22],
							},
						]}
						options={{
							chart: {
								type: "bar",
								stacked: true,
								toolbar: {
									show: false,
								},
							},
							xaxis: {
								categories: [
									["1.", ...EscalaComprometimento[1].titulo.split(" ")],
									["2.", ...EscalaComprometimento[2].titulo.split(" ")],
									["3.", ...EscalaComprometimento[3].titulo.split(" ")],
									["4.", ...EscalaComprometimento[4].titulo.split(" ")],
									["5.", ...EscalaComprometimento[5].titulo.split(" ")],
								],
							},
							yaxis: {
								labels: {
									formatter: (value) => `${value}%`,
								},
							},
							dataLabels: {
								enabled: true,
								formatter: (value) => `${value}%`,
							},
							plotOptions: {
								bar: {
									borderRadius: 10,
								},
							},
							legend: {
								position: "right",
							},
							tooltip: {
								enabled: true,
								shared: true,
								intersect: false,
							},
						}}
						height={400}
					/>
				</Col>
				<Col lg={12}>
					<DashboardChartCard
						title="Média de Índice de Danos Técnicos"
						type="bar"
						series={[
							{
								name: "Média de Índice",
								data: [4.4, 5, 4.1, 2.7, 2.2, 5, 4.1],
							},
						]}
						options={{
							chart: {
								type: "bar",
								toolbar: {
									show: false,
								},
							},
							xaxis: {
								categories: [["Fundação DANO"], ["Fundação DANO"], ["Fundação DANO"], ["Fundação DANO"], ["Fundação DANO"], ["Fundação DANO"], ["Fundação DANO"]],
								labels: {},
							},
							yaxis: {
								title: {
									text: "Índice de Danos Técnicos",
								},
								max: 5,
								min: 0,
							},
							dataLabels: {
								enabled: true,
								offsetY: -20,
								style: {
									colors: ["#304758"],
								},
							},
							plotOptions: {
								bar: {
									borderRadius: 10,
									dataLabels: {
										position: "top", // top, center, bottom
									},
								},
							},
						}}
						height={400}
					/>
				</Col>
				<Col lg={12}>
					<DashboardChartCard
						title="Média de Índice de Danos Funcionais"
						type="bar"
						series={[
							{
								name: "Média de Índice",
								data: [4.4, 5, 4.1, 2.7, 2.2, 5, 4.1],
							},
						]}
						options={{
							chart: {
								type: "bar",
								toolbar: {
									show: false,
								},
							},
							xaxis: {
								categories: [["Inst-Hidro-DANO"], ["Inst-Hidro-DANO"], ["Inst-Hidro-DANO"], ["Inst-Hidro-DANO"], ["Inst-Hidro-DANO"], ["Inst-Hidro-DANO"], ["Inst-Hidro-DANO"]],
								labels: {},
							},
							yaxis: {
								title: {
									text: "Índice de Danos Funcionais",
								},
								max: 5,
								min: 0,
							},
							dataLabels: {
								enabled: true,
								offsetY: -20,
								style: {
									colors: ["#304758"],
								},
							},
							plotOptions: {
								bar: {
									borderRadius: 10,
									dataLabels: {
										position: "top", // top, center, bottom
									},
								},
							},
						}}
						height={400}
					/>
				</Col>
				<Col lg={12}>
					<DashboardChartCard
						title="Média de Índice de Danos Técnicos x Nível de Inundação"
						type="bar"
						series={[
							{
								name: "Nível 1",
								data: [1.4, 1, 1.1, 1.7, 2.2, 1, 1.1],
							},
							{
								name: "Nível 2",
								data: [2.4, 2, 2.1, 2.7, 2.2, 2, 2.1],
							},
							{
								name: "Nível 3",
								data: [2.4, 3, 2.1, 2.7, 2.2, 2, 2.1],
							},

							{
								name: "Nível 4",
								data: [3.4, 4, 3.1, 3.7, 3.2, 3, 3.1],
							},

							{
								name: "Nível 5",
								data: [4.4, 5, 4.1, 4.7, 4.2, 5, 4.1],
							},
						]}
						options={{
							chart: {
								type: "bar",
								toolbar: {
									show: false,
								},
							},
							xaxis: {
								categories: [["Fundação DANO"], ["Fundação DANO"], ["Fundação DANO"], ["Fundação DANO"], ["Fundação DANO"], ["Fundação DANO"], ["Fundação DANO"]],
								labels: {},
							},
							yaxis: {
								title: {
									text: "Índice de Danos Técnicos",
								},
								max: 5,
								min: 0,
							},
							dataLabels: {
								enabled: true,
								offsetY: -20,
								style: {
									colors: ["#304758"],
								},
							},
							plotOptions: {
								bar: {
									borderRadius: 10,
									dataLabels: {
										position: "top", // top, center, bottom
									},
								},
							},
							tooltip: {
								enabled: true,
								shared: true,
								intersect: false,
							},
						}}
						height={400}
					/>
				</Col>
				<Col lg={12}>
					<DashboardChartCard
						title="Média de Índice de Danos Funcionais x Nível de Inundação"
						type="bar"
						series={[
							{
								name: "Nível 1",
								data: [1.4, 1, 1.1, 1.7, 2.2, 1, 1.1],
							},
							{
								name: "Nível 2",
								data: [2.4, 2, 2.1, 2.7, 2.2, 2, 2.1],
							},
							{
								name: "Nível 3",
								data: [2.4, 3, 2.1, 2.7, 2.2, 2, 2.1],
							},

							{
								name: "Nível 4",
								data: [3.4, 4, 3.1, 3.7, 3.2, 3, 3.1],
							},

							{
								name: "Nível 5",
								data: [4.4, 5, 4.1, 4.7, 4.2, 5, 4.1],
							},
						]}
						options={{
							chart: {
								type: "bar",
								toolbar: {
									show: false,
								},
							},
							xaxis: {
								categories: [["Inst-Hidro-DANO"], ["Inst-Hidro-DANO"], ["Inst-Hidro-DANO"], ["Inst-Hidro-DANO"], ["Inst-Hidro-DANO"], ["Inst-Hidro-DANO"], ["Inst-Hidro-DANO"]],
								labels: {},
							},
							yaxis: {
								title: {
									text: "Índice de Danos Funcionais",
								},
								max: 5,
								min: 0,
							},
							dataLabels: {
								enabled: true,
								offsetY: -20,
								style: {
									colors: ["#304758"],
								},
							},
							plotOptions: {
								bar: {
									borderRadius: 10,
									dataLabels: {
										position: "top", // top, center, bottom
									},
								},
							},
							tooltip: {
								enabled: true,
								shared: true,
								intersect: false,
							},
						}}
						height={400}
					/>
				</Col>
				<Col lg={4}>
					<DashboardChartCard
						title="Índice de Danos Funcionais"
						type="radialBar"
						series={[70]}
						options={{
							chart: {
								type: "radialBar",
								toolbar: {
									show: false,
								},
							},
							labels: ["Danos estruturais"],
						}}
						height={400}
					/>
				</Col>
				<Col lg={4}>
					<DashboardChartCard
						title="Índice de Danos Funcionais"
						type="radialBar"
						series={[70]}
						options={{
							chart: {
								type: "radialBar",
								toolbar: {
									show: false,
								},
							},
							labels: ["Danos estruturais"],
						}}
						height={400}
					/>
				</Col>
				<Col lg={4}>
					<DashboardChartCard
						title="Índice de Danos Funcionais"
						type="radialBar"
						series={[70]}
						options={{
							chart: {
								type: "radialBar",
								toolbar: {
									show: false,
								},
							},
							labels: ["Danos estruturais"],
						}}
						height={400}
					/>
				</Col>
			</Row>

			<Row className="mb-4">
				{screens
					.filter((screen) => screen.showDashboard)
					.filter(
						(screen) =>
							screen.module === null ||
							user?.modules?.some((module) => {
								return module.id === screen.module;
							})
					)
					.filter((screen) => {
						return screen.superUser ? user?.superUser : true;
					})
					.map((screen, screenKey) => {
						return (
							<Col lg={6} key={screenKey} className="mb-2">
								<ListCard
									onClick={() => {
										handleClick(screen.route);
									}}
								>
									<ListCard.Icon>
										<Icon path={screen.icon} size={1} className="text-muted me-3" />
									</ListCard.Icon>
									<ListCard.Content>
										<div className="flex-fill me-2">
											<div className="text-muted fw-lighter">{screen.name}</div>
											<div className="fw-light">{screen.description}</div>
										</div>
									</ListCard.Content>
									<ListCard.Action>
										<Icon path={mdiChevronRight} size={1} className="text-muted m-2" />
									</ListCard.Action>
								</ListCard>
							</Col>
						);
					})}
			</Row>
		</Layout>
	);
}
